<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { createRecordV2 } from '@/services/api/v2/records.v2.api';
import { REUSE_POTENTIAL_INDEX } from '@/modules/modules';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'ReusePotentialIndex',
  components: { AntInput, ModuleNavigationBar },
  data: () => {
    return {
      variants: [],
      variantsTableId: null,
      newVariantMenu: false,
      newVariantName: '',
      newVariantContact: '',
      variantLoading: false,
      focusedVariantId: '',
      focusedCrossSectionId: '',
      navigation: 'overview',
    };
  },
  computed: {
    ...mapGetters(['project']),
  },
  watch: {
    navigation(value) {
      switch (value) {
        case 'overview':
          this.$router.push({
            name: `${REUSE_POTENTIAL_INDEX}`,
          });
          this.focusedVariantId = '';
          this.fetchVariants();
          break;

        case 'cross-sections':
          this.$router.push({
            name: `${REUSE_POTENTIAL_INDEX}-cross-sections`,
            params: { variant: this.focusedVariantId },
          });
          break;

        case 'calculation':
          this.$router.push({
            name: `${REUSE_POTENTIAL_INDEX}-calculation`,
            params: {
              variant: this.focusedVariantId,
              crossSection: this.focusedCrossSectionId,
            },
          });
          break;

        case 'results':
          this.$router.push({
            name: `${REUSE_POTENTIAL_INDEX}-results`,
            params: {
              variant: this.focusedVariantId,
            },
          });
          break;
      }
    },
    $route: {
      immediate: true,
      handler(value) {
        this.setNavigation();
        this.setFocusedVariant();
        this.setFocusedCrossSection();
      },
    },
  },
  mounted() {
    this.setNavigation();
    this.fetchVariants();
    this.setFocusedVariant();
    this.setFocusedCrossSection();
  },
  methods: {
    setNavigation() {
      switch (this.$route.name) {
        case REUSE_POTENTIAL_INDEX:
          this.navigation = 'overview';
          break;
        case `${REUSE_POTENTIAL_INDEX}-cross-sections`:
          this.navigation = 'cross-sections';
          break;
        case `${REUSE_POTENTIAL_INDEX}-calculation`:
          this.navigation = 'calculation';
          break;
        case `${REUSE_POTENTIAL_INDEX}-results`:
          this.navigation = 'results';
          break;
      }
    },
    setFocusedVariant() {
      if (this.$route.params.variant) {
        this.focusedVariantId = this.$route.params.variant;
      }
    },
    setFocusedCrossSection() {
      if (this.$route.params.crossSection) {
        this.focusedCrossSectionId = this.$route.params.crossSection;
      }
    },
    async fetchVariants() {
      this.variantLoading = true;
      try {
        let { variants } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_RPI_VARIANTS',
              project: this.project.id,
              as: 'variants',
              columns: [
                {
                  name: 'name',
                },
                {
                  name: 'contact',
                },
                {
                  name: 'date',
                },
              ],
            },
          ],
        });
        this.variants = variants.records;
        this.variantsTableId = variants.id;
      } catch (e) {}
      this.variantLoading = false;
    },
    async createVariant() {
      this.variantLoading = true;
      try {
        let variant = await createRecordV2(this.variantsTableId, {
          name: this.newVariantName,
          contact: this.newVariantContact,
          date: moment().format('YYYY-MM-DD'),
        });
        this.variants.push(variant);
        this.newVariantMenu = false;
        this.newVariantName = '';
        this.newVariantContact = '';
      } catch (e) {}
      this.variantLoading = false;
    },
    focusOnVariant(variant) {
      this.focusedVariantId = variant.id;
      this.navigation = 'cross-sections';
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column overflow-y-auto">
    <module-navigation-bar title="">
      <template #module-nav-header>
        Reuse Potential Index (RP
        <div class="d-flex align-end fs-10" style="height: 30px">E.D.</div>
        )
      </template>
      <template #module-nav-actions>
        <v-btn-toggle v-model="navigation" tile color="primary" group>
          <v-btn value="overview"> Overzicht</v-btn>

          <v-btn v-if="focusedVariantId" value="cross-sections">
            Doorsnedes
          </v-btn>

          <v-btn
            v-if="focusedVariantId && focusedCrossSectionId"
            value="calculation"
          >
            Berekening
          </v-btn>

          <v-btn v-if="focusedVariantId" value="results"> Resultaten</v-btn>
        </v-btn-toggle>
      </template>
    </module-navigation-bar>
    <div
      class="d-flex flex-column overflow-auto flex-grow-1"
      style="width: 100vw"
    >
      <span class="fs-12 my-1 mx-5">@ Method E. Durmisevic</span>
      <div class="flex-grow-1 d-flex overflow-auto" style="height: 0">
        <router-view v-if="focusedVariantId" class="flex-grow-1 d-flex" />
        <div v-else class="d-flex flex-column px-5 flex-grow-1">
          <div class="mb-5 pa-5 background-white full-width">
            <v-subheader class="subheader">Uitleg</v-subheader>
            <p>
              Om de Reuse Potential Index (RPI) te berekenen, moet je de
              volgende stappen doorlopen.<br /><br />

              1. Het aanmaken van een variant. Per ontwerp kunnen er
              verschillende varianten doorgerekend worden. Hier heeft u een
              overzicht van de verschillende varianten en kan u een variant
              aanmaken.<br /><br />

              2. Per variant kunt u een referentiedoorsnede maken. Dit is een
              opbouw van een gevel die u gaat berekenen. Elke doorsnede heeft
              een bepaalde dekkingsgraad (De verhouding van de gevelopbouw ten
              opzichte van de totale gevel). Dit kan aangegeven worden in
              procenten, meters of andere units (zolang u dit maar gelijk
              houd).<br /><br />

              3. Per referentiedoorsnede kunt u de onderdelen toevoegen.
              Vervolgens kan u per onderdeel aangeven welke eigenschappen het
              onderdeel heeft en met welke andere onderdelen dit mee in
              verbinding staat.<br /><br />

              4. Vervolgens kunt u naar het tab resultaten gaan om de RPI te
              bekijken, te vergelijken met andere referntie doorsnedes t.o.v.
              het totaal. Daarnaast zijn er tips te vinden om de
              herbruikbaarheid te vergroten.<br /><br />
            </p>
          </div>
          <v-data-table
            :items="variants"
            :headers="[
              { text: 'Naam', value: 'name' },
              { text: 'Contact persoon', value: 'contact' },
              { text: 'Aangemaakt op', value: 'date' },
            ]"
            :loading="variantLoading"
            sort-by="date"
            sort-desc
            @click:row="focusOnVariant"
          >
            <template #item.date="{ value }">
              {{ formatDate(value) }}
            </template>
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Varianten</v-toolbar-title>
                <v-spacer />
                <v-menu
                  v-model="newVariantMenu"
                  left
                  :close-on-content-click="false"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-text>
                      <ant-input label="Naam">
                        <template #input-field>
                          <v-text-field
                            v-model="newVariantName"
                            placeholder="Naam"
                            filled
                            hide-details
                            autofocus
                            dense
                            single-line
                            class="mb-2"
                          />
                        </template>
                      </ant-input>
                      <ant-input label="Contact persoon">
                        <template #input-field>
                          <v-text-field
                            v-model="newVariantContact"
                            placeholder="Contact persoon"
                            filled
                            dense
                            hide-details
                            single-line
                            class="mb-2"
                          />
                        </template>
                      </ant-input>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn small color="primary" @click="createVariant"
                        >opslaan
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-toolbar>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="fs-12 my-1 mx-5">
        <span class="font-weight-bold">©4D Architects Amsterdam</span>
        (RP<span style="font-size: 6px">E.D.</span>) Method Elma Durmisevic
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.subheader {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: var(--v-primary-base);
  font-size: 20px;
}
</style>
