var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"90%"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"elevation":"0","small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Details")])]}}]),model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 text-primary"},[_vm._v(" Beoordeling per onderdeel voor doorsnede "+_vm._s(_vm.crossSection.name)+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","items":_vm.crossSection.components,"headers":[
          { text: 'Component', value: 'name' },
          {
            text: 'Functionele Ontkoppeling (2)',
            value: 'functionalDisconnectionScore',
          },
          { text: 'Productniveaus (2)', value: 'productLevelScore' },
          {
            text: 'Aantal Relaties (2)',
            value: 'componentConnectionScore',
            align: 'center',
          },
          { text: 'Levensduur (2)', value: 'lifespanScore', align: 'center' },
          {
            text: 'Type Verbindingen (3)',
            value: 'connectionTypeScore',
            align: 'center',
          },
          {
            text: 'Verbinding Schade (3)',
            value: 'connectionDamageScore',
            align: 'center',
          },
          {
            text: '',
            value: 'space',
          },
          {
            text: 'Totaal',
            value: 'total',
            align: 'center',
          },
        ],"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('td',[_vm._v(_vm._s(item.nr)+". "+_vm._s(item.name))])]}},_vm._l(([
            'functionalDisconnectionScore',
            'productLevelScore',
            'lifespanScore',
            'componentConnectionScore',
            'connectionTypeScore',
            'connectionDamageScore',
          ]),function(key){return {key:`item.${key}`,fn:function({ item, value }){return [_c('td',{key:`${item.id}-${key}`,staticClass:"text-center",style:({
              'background-color': _vm.getHexColor(value),
            })},[_vm._v(" "+_vm._s(value >= 0 ? value : '-')+" ")])]}}}),{key:"item.total",fn:function({ item }){return [_c('td',{staticClass:"text-center ant-border-left-darker",style:({
              'background-color': _vm.getHexColor(_vm.getComponentScore(item)),
            })},[_vm._v(" "+_vm._s(_vm.getComponentScore(item))+" ")])]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }