export const lifespanOptions = [
  {
    id: 1,
    label: 'Aluminium, algemeen',
    years: 75,
  },
  {
    id: 2,
    label: 'Aluminium, plaat gevel/dak',
    years: 40,
  },
  {
    id: 3,
    label: 'Asbest, algemeen',
    years: 60,
  },
  {
    id: 4,
    label: 'Asbest, fittingen',
    years: 75,
  },
  {
    id: 5,
    label: 'Baksteen, algemeen',
    years: 100,
  },
  {
    id: 6,
    label: 'Baksteen, gevel',
    years: 75,
  },
  {
    id: 7,
    label: 'Beton, algemeen',
    years: 100,
    yearsBefore: 500,
  },
  {
    id: 8,
    label: 'beton, prefab',
    years: 75,
    yearsBefore: 500,
  },
  {
    id: 9,
    label: 'Bitumen',
    years: 30,
  },
  {
    id: 10,
    label: 'Gipsplaat, algemeen',
    years: 50,
  },
  {
    id: 11,
    label: 'Gipsplaat, verlaagd plafond',
    years: 20,
  },
  {
    id: 12,
    label: 'Glas, algemeen',
    years: 75,
    yearsBefore: 500,
  },
  {
    id: 13,
    label: 'Glas, ruiten, algemeen',
    years: 30,
  },
  {
    id: 14,
    label: 'Glas, ruiten, gehard',
    years: 40,
  },
  {
    id: 15,
    label: 'Hout, algemeen',
    years: 75,
    yearsBefore: 300,
  },
  {
    id: 16,
    label: 'Hout, behandeld hardhout (buiten)',
    years: 50,
  },
  {
    id: 17,
    label: 'Hout, behandeld zachthout (buiten)',
    years: 30,
  },
  {
    id: 18,
    label: 'Hout, cementvezelplaat (buiten)',
    years: 25,
  },
  {
    id: 19,
    label: 'Hout, dragend, algemeen, CLT',
    years: 100,
    yearsBefore: 500,
  },
  {
    id: 20,
    label: 'Hout, dragend, OSB, LVL, LSL',
    years: 75,
  },
  {
    id: 21,
    label: 'Hout, parket',
    years: 40,
  },
  {
    id: 22,
    label: 'Hout, ramen/deuren (binnen)',
    years: 100,
    yearsBefore: 500,
  },
  {
    id: 23,
    label: 'Hout, voor houtskelet binnenwanden',
    years: 50,
  },
  {
    id: 24,
    label: 'Isolatie, algemeen',
    years: 75,
  },
  {
    id: 25,
    label: 'Isolatie, cellulose/houtvezelvlokken, schapenwol',
    years: 30,
  },
  {
    id: 26,
    label: 'Keramiek, sanitair',
    years: 50,
    yearsBefore: 300,
  },
  {
    id: 27,
    label: 'Keramiek, tegels en gevelbekleding gelijmd',
    years: 40,
    yearsBefore: 300,
  },
  {
    id: 28,
    label: 'Keramiek, tegels en gevelbekleding mechanische verbinding',
    years: 75,
    yearsBefore: 300,
  },
  {
    id: 29,
    label: 'Koper, algemeen',
    years: 100,
  },
  {
    id: 30,
    label: 'Koper, eindleiding',
    years: 25,
  },
  {
    id: 31,
    label: 'Koper, hoofdleiding',
    years: 50,
  },
  {
    id: 32,
    label: 'Kunststof, acrylglas',
    years: 40,
  },
  {
    id: 33,
    label: 'Kunststof, EPDM / POCB dakbedekking',
    years: 40,
  },
  {
    id: 34,
    label: 'Kunststof, EPDM waterkerende laag',
    years: 50,
  },
  {
    id: 35,
    label: 'Kunststof, HPL plaat (binnen)',
    years: 75,
  },
  {
    id: 36,
    label: 'Kunststof, HPL plaat (buiten)',
    years: 25,
  },
  {
    id: 37,
    label: 'Kunststof, kozijnen',
    years: 40,
  },
  {
    id: 38,
    label: 'Kunststof, PE waterkerende laag',
    years: 50,
  },
  {
    id: 39,
    label: 'Kunststof, polycarbonaat plaat',
    years: 75,
  },
  {
    id: 40,
    label: 'Kunststof, PVC dakbedekking',
    years: 30,
  },
  {
    id: 41,
    label: 'Kunststof, PVC/PE eindleiding',
    years: 25,
  },
  {
    id: 42,
    label: 'Kunststof, PVC/PE hoofdleiding',
    years: 50,
  },
  {
    id: 43,
    label: 'Kurklinoleum, en onderliggende lagen (ondervloer, folies, etc.)',
    years: 30,
  },
  {
    id: 44,
    label: 'Linoleum, en onderliggende lagen (ondervloer, folies, etc.)',
    years: 30,
  },
  {
    id: 45,
    label: 'Lood',
    years: 25,
  },
  {
    id: 46,
    label: 'Pleister, binnen',
    years: 15,
  },
  {
    id: 47,
    label: 'Pleister, buiten',
    years: 25,
  },
  {
    id: 48,
    label: 'Pleister, leem',
    years: 50,
  },
  {
    id: 49,
    label: 'Sandwich paneel, HPL',
    years: 40,
  },
  {
    id: 50,
    label: 'Sandwich paneel, HPL',
    years: 25,
  },
  {
    id: 51,
    label: 'Sandwich paneel, metaal binnen',
    years: 75,
  },
  {
    id: 52,
    label: 'Sandwich paneel, PVC/polyester',
    years: 30,
  },
  {
    id: 53,
    label: 'Sandwich paneel, staal gevel/dak',
    years: 50,
  },
  {
    id: 54,
    label: 'Staal, algemeen',
    years: 100,
    yearsBefore: 100,
  },
  {
    id: 55,
    label: 'Staal, installaties eindleiding',
    years: 25,
  },
  {
    id: 56,
    label: 'Staal, installaties hoofdleiding',
    years: 50,
  },
  {
    id: 57,
    label: 'Staal, metalstud wand <0,8mm profieldikte',
    years: 50,
  },
  {
    id: 58,
    label: 'Staal, metalstud wand >=0,8mm profieldikte',
    years: 75,
  },
  {
    id: 59,
    label: 'Staal, plaat gegalvaniseerd gepoedercoat dak/gevel',
    years: 50,
  },
  {
    id: 60,
    label: 'Steen, algemeen',
    years: 100,
    yearsBefore: 500,
  },
  {
    id: 61,
    label: 'Steen, gevelbekleding',
    years: 75,
  },
  {
    id: 62,
    label: 'Steen, kalksteen',
    years: 75,
    yearsBefore: 300,
  },
  {
    id: 63,
    label: 'Tapijt, en onderliggende lagen (ondervloer, folies, etc.)',
    years: 30,
  },
  {
    id: 64,
    label: 'Zink, algemeen',
    years: 40,
  },
  {
    id: 65,
    label: 'Zink, hwa',
    years: 75,
  },
];
