export function getHexColor(percentage) {
  let r, g, b;
  percentage = percentage * 100;

  if (percentage >= 50) {
    // from orange to green
    r = Math.floor((255 * (100 - percentage) * 2) / 100);
    g = Math.floor(255);
    b = 0;
  } else {
    // from red to orange
    r = Math.floor(255);
    g = Math.floor((255 * percentage * 2) / 100);
    b = 0;
  }

  return (
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0')
  );
}
